import React, { useEffect, useState } from 'react';
import './TopNavBar.css'
import { NavLink, useNavigate } from 'react-router-dom';
import downArrowIcon from "../../assets/sidebar/down-Arrow.png";
import upArrow from "../../assets/sidebar/upArrow.png";
import { showAlert } from '../utills/sweetAlert';
import Streetlights from "../../assets/sidebar/Streetlights.png";
import logo from "../../assets/logo.png";

const TopNavBar: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);


    const toggleMenu = () => {
        setOpen(!open);
    }

    return (
        <nav>
            <div className="mobile_nav">
                <Logo />
                <button type="button" onClick={toggleMenu}>
                    <MenuIcon open={open} />
                    <CloseIcon open={open} />
                </button>
            </div>

            <List open={open} />
        </nav>
    );
}

const Logo: React.FC = () => {
    return (
        <div className="topnav">
            <a className="topnavactive">
                <img src={logo} alt="Streetlights" className="streetlights" />
            </a>
        </div>
    );
}

interface ListProps {
    open: boolean;
}

const List: React.FC<ListProps> = ({ open }) => {
    const navigate = useNavigate();

    const confirmLogout = async () => {
        console.log("logout");
        const isComfirm = await showAlert("Please Confirm", "Are you sure you want to logout?", "Yes", "No")
        if (isComfirm) {
            navigate("/");
        }
    };

    return (
        <ul className={open ? "mblshow" : ""}>

            <li>
                <NavLink
                    to="/layout/dashboard"
                    className="menu-button mob-nav"
                >
                    Dashboard
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/layout/vendor"
                    className="menu-button mob-nav"
                >
                    Vendor Master
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/layout/sitemaster"
                    className="menu-button mob-nav"
                >
                    Site Master
                </NavLink>
            </li>

            <li>
                <NavLink
                    className="menu-button mob-nav"
                    to="/layout/report"
                >
                    {open && (
                        <span>Report</span>
                    )}
                </NavLink>
            </li>

            <li>
                <NavLink
                    className="menu-button mob-nav"
                    to="/layout/CustomerMangement"
                >
                    {open && (
                        <span>Customer</span>
                    )}
                </NavLink>
            </li>

            <li onClick={() => confirmLogout()}>
                <a
                    className="menu-button mob-nav">
                    {open && <span>Logout</span>}
                </a>
            </li>
        </ul>
    );
}

interface MenuIconProps {
    open: boolean;
}

const MenuIcon: React.FC<MenuIconProps> = ({ open }) => {
    return (
        <svg className={open ? "close" : "menu"} fill="#000000" viewBox="0 0 448 512" width="30" xlinkTitle="bars">
            <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
        </svg>
    );
}

const CloseIcon: React.FC<MenuIconProps> = ({ open }) => {
    return (
        <svg className={open ? "menu" : "close"} fill="#000000" viewBox="0 0 512 512" width="40" xlinkTitle="window-close">
            <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z" />
        </svg>
    );
}

export default TopNavBar;